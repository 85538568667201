import React, { useState, useRef, useEffect } from 'react';
import { FaSearch, FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FiEdit, FiTrash, FiUpload } from 'react-icons/fi';
import UploadData from './UploadInput';
import { Link } from 'react-router-dom';
import VideoClass from '../Auth/video.class';
import { truncateString } from '../Auth/auth';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { toast,ToastContainer } from 'react-toastify';

function EditVideo() {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTopic, setSelectedTopic] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const itemsPerPage = 7;
    const [allVideo, setAllVideo] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const pageNumbersToShow = 5; 
    const [isLoading, setIsLoading] = useState(false);
    const dropdownRef = useRef(null);

    const topics = ['Depression', 'Lust', 'Anger', 'Finances', 'Discipline'];

    const handleSearchChange = (e) => setSearchTerm(e.target.value);

    const fetchVideos = async (page) => {
        setIsLoading(true);
        try {
            const res = await VideoClass.getAll({
                page,
                per_page: 10,
                search: searchTerm,
                category:selectedTopic
            });
            console.log("Fetched videos:", res?.data);
            setAllVideo(res?.data?.videos || []); // Ensure it's an empty array if no videos
            setTotalPages(res?.data?.total_pages || 0);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchVideos(currentPage);
    }, [currentPage, searchTerm, selectedTopic]);

    const handleTopicChange = (topic) => {
        setSelectedTopic(topic);
        setIsDropdownOpen(false);
    };

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const fileInputRef = useRef(null);

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            console.log("Selected file:", file);
        }
    };

    const [isEditing, setIsEditing] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [name, setName] = useState('');
    const [text, setText] = useState('');

    const handleEditClick = (item) => {
        setSelectedItem(item);
        setName(item.title);
        setText(item.summary);
        setIsEditing(true);
    };

    const handleSaveChanges = () => {
        const updatedItem = { ...selectedItem, name, text };
        const updatedUploadData = UploadData.map((item) =>
            item === selectedItem ? updatedItem : item
        );
        setIsEditing(false);
        setSelectedItem(null);
    };

    const handlePageChange = (page) => setCurrentPage(page);

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        summary: Yup.string()
          .max(1000000, 'Summary cannot exceed 1000 characters')
          .required('Summary is required'),
       
      });
console.log("selectedItem",selectedItem)
      const initialValues = {
        title: selectedItem?.title,
        summary:selectedItem?.summary
    };


    const onSubmit = async(values) => {
        console.log("values",values)
        try{
            setIsLoading(true)
            const data ={
                title: values['title'],
                summary: values['summary'],
                id: selectedItem['id'],
            }
            const response = await VideoClass.update(data);
            toast(response?.data?.message || response.response?.data?.message);
            console.log("response from video",response)
            console.log("data from submit",data)
            isDropdownOpen(false)
            setIsLoading(false)
        }catch(err){
            setIsLoading(false)
            isDropdownOpen(false)
            console.log("error",err)
        }

    };

    const renderPageNumbers = () => {
        const startPage = Math.max(1, currentPage - Math.floor(pageNumbersToShow / 2));
        const endPage = Math.min(totalPages, startPage + pageNumbersToShow - 1);

        const pageNumbers = [];
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={`px-4 py-2 ${currentPage === i ? 'bg-purp text-white rounded-lg' : 'bg-white text-bluebg'}`}
                >
                    {i}
                </button>
            );
        }

        return pageNumbers;
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = UploadData.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className='w-full font-poppins p-4'>
            <div className='flex flex-col'>
                <h1 className='text-bluebg p-3 text-2xl md:text-3xl md:mx-20'>Edit Video</h1>
                <p className='md:mx-20 text-bluebg p-3 font-light'>
                    Make changes to uploaded videos
                </p>
            </div>
            <div className="max-w-[1240px] grid md:grid-cols-2 gap-12 items-center mx-auto">
  {/* Search bars */}
  <div className="flex flex-col md:flex-row gap-5 justify-between w-full mx-auto">
    <div className="flex items-center relative w-full md:w-1/2">
      <input
        type="text"
        placeholder="Search videos..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="p-2 bg-lblue border border-gray-300 rounded-full w-full pr-10"
      />
      <FaSearch className="absolute right-3 text-gray-500" />
    </div>

    <div className="relative w-full md:w-1/2" ref={dropdownRef}>
      <div
        onClick={toggleDropdown}
        className="flex items-center p-2 bg-lblue border border-gray-300 rounded-md cursor-pointer"
        style={{ zIndex: 10 }}
      >
        <span className="flex-1">{selectedTopic || "Filter by Category"}</span>
        <FaChevronDown
          className={`transition-transform duration-200 ${isDropdownOpen ? "transform rotate-180" : ""}`}
        />
      </div>
      {isDropdownOpen && (
        <div className="absolute top-full left-0 mt-2 bg-white border border-gray-300 rounded-md w-full z-20">
          {topics.map((topic, index) => (
            <div
              key={index}
              onClick={() => handleTopicChange(topic)}
              className="p-2 hover:bg-gray-100 cursor-pointer"
            >
              {topic}
            </div>
          ))}
        </div>
      )}
    </div>
  </div>

  {/* Buttons */}
  <div className="flex flex-col md:flex-row justify-evenly space-y-4 md:space-y-0 md:space-x-4 w-full">
    <Link to="/uploadvideo">
      <button
        className="flex flex-row items-center justify-center hover:bg-white hover:text-bluebg border border-bluebg bg-bluebg rounded-lg text-white px-4 py-2 space-x-2 w-full md:w-64"
      >
        <FiUpload size={20} />
        <span>Upload Video Manually</span>
      </button>
    </Link>

    <Link to="/uploadvidlink">
      <button
        className="flex flex-row items-center justify-center hover:bg-white hover:text-bluebg border border-bluebg bg-bluebg rounded-lg text-white px-4 py-2 space-x-2 w-full md:w-64"
      >
        <FiUpload size={20} />
        <span>Upload Video By Link</span>
      </button>
    </Link>
{/* 
    <button
  className="flex flex-row items-center justify-center hover:bg-red-500 border border-bluebg bg-red-700 rounded-lg text-white px-4 py-2 space-x-2 w-full md:w-64 whitespace-nowrap"
>
  <FiTrash size={20} />
  <span>Delete Video</span>
</button> */}

  </div>
</div>


            <div className='flex flex-col mx-auto space-y-4 mt-10 items-center justify-center'>
                
                {allVideo.map((item, id) => (
                    <div key={id} className='flex flex-col md:flex-row w-full max-w-[900px] bg-lightblue p-2 items-center rounded-lg'>
                        <img src={item.image} alt='/img' className='rounded-md w-[150px] mr-4' />
                        <div className='flex-1'>
                            <h1 className='text-bluebg text-center text-xl font-semibold'>{item.title}</h1>
                            <p className='text-gray-700'>{truncateString(item.summary)}</p>
                        </div>
                        <button
                            className='flex items-center rounded-lg bg-transparent border border-bluebg p-2 px-3 ml-4 hover:bg-bluebg hover:text-white'
                            onClick={() => handleEditClick(item)}
                        >
                            <FiEdit size={16} className='mr-2' />
                            Edit Video
                        </button>
                    </div>
                ))}

                
                <div className='flex justify-end space-x-2 mt-4'>
                    <button
                        onClick={goToPreviousPage}
                        className={`px-4 py-2 ${currentPage === 1 ? 'text-gray-400 cursor-not-allowed' : 'bg-white text-bluebg'}`}
                        disabled={currentPage === 1}
                    >
                        <FaChevronLeft />
                    </button>
                    {renderPageNumbers()}
                    <button
                        onClick={goToNextPage}
                        className={`px-4 py-2 ${currentPage === totalPages ? 'text-gray-400 cursor-not-allowed' : 'bg-white text-bluebg'}`}
                        disabled={currentPage === totalPages}
                    >
                        <FaChevronRight />
                    </button>
                </div>
            </div>

            
            {isEditing && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="bg-white p-6 rounded-lg shadow-lg w-[300px]">
                    <h2 className="text-bluebg text-xl mb-4">Edit Video Details</h2>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <div>
                                    <label className="block text-gray-700 mb-2">Video Name</label>
                                    <Field
                                        type="text"
                                        name="title"
                                        className="w-full p-2 mb-1 border rounded"
                                    />
                                    {errors.title && touched.title && (
                                        <div className="text-red-500 text-sm mb-2">{errors.title}</div>
                                    )}
                                </div>
                                <div>
                                    <label className="block text-gray-700 mb-2"> Description</label>
                                    <Field
                                        as="textarea"
                                        name="summary"
                                        className="w-full p-2 mb-1 border rounded"
                                    />
                                    {errors.summary && touched. summary && (
                                        <div className="text-red-500 text-sm mb-2">{errors.summary}</div>
                                    )}
                                </div>
                                <div className="flex justify-end space-x-2">
                                    <button
                                        type="button"
                                        className="px-4 py-2 bg-gray-300 rounded"
                                        onClick={() => setIsEditing(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="px-4 py-2 bg-bluebg text-white rounded"
                                    >
                                       {isLoading ? "Loading..." : " Save Changes"}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <ToastContainer/>
            </div>
            )}
        </div>
    );
}

export default EditVideo;
