
import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu, AiOutlineDown, AiOutlineUp, AiOutlineEdit } from 'react-icons/ai';
import { FaVideo, FaMedal } from 'react-icons/fa';
import { Link, redirect } from 'react-router-dom';
import Logo from '../Assets/image 2.png';
import logo2 from '../Assets/image 392.png';
import axios from 'axios'; // Import axios
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AuthService from '../Auth/AuthService';
import { Button } from '@mui/material';
import { logOut } from '../Auth/auth';
import { toast,ToastContainer } from 'react-toastify';


function Nav() {
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("");

  const [nav, setNav] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [profiles, setProfiles] = useState([
    { id: 1, first_name: 'John', last_name: 'Doe', email: 'john.doe@example.com', image: '/path/to/default-profile1.png' },
    { id: 2, first_name: 'Jane', last_name: 'Smith', email: 'jane.smith@example.com', image: '/path/to/default-profile2.png' },
    { id: 3, first_name: 'Alex', last_name: 'Johnson', email: 'alex.johnson@example.com', image: '/path/to/default-profile3.png' },
  ]);
  const [editProfile, setEditProfile] = useState(profiles[0]);

  const user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
  const userType = user?.userType 
  
  const isLoggedIn = !!user || false;
  const handleNav = () => {
    setNav(!nav);
  };

  const handleProfileClick = () => {
    setProfileDropdown(!profileDropdown);
  };

  const handleProfileChange = (id) => {
    const newProfile = profiles.find((profile) => profile.id === id);
    if (newProfile) {
      setSelectedProfileId(id);
      setEditProfile(newProfile);
      setProfileDropdown(false);
    }
  };

  // Formik for Add Admin
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true)
        const response = await AuthService.createAdmin(values);
        console.log('Admin added successfully:', response?.data);
        toast(response?.data?.message || response.response?.data?.message);
        setTimeout(()=>{
          window.location.href ="/admin"
        },3000)
        setIsLoading(false)
        resetForm();
        setShowPopup(false);
      } catch (error) {
        console.error('Error adding admin:', error);
       setTimeout(()=>{
         window.location.href ="/admin"
       },3000)
        setIsLoading(false)
        toast('Failed to add admin. Please try again.');
      
      }
    },
  });

  return (
    <div>
      <nav className='p-3 bg-purp font-poppins text-white shadow md:flex md:items-center md:justify-between'>
        <div className='flex justify-between items-center w-full md:w-1/3'>
          <Link to={isLoggedIn ? "/userpage" :"/"}>
            <span className='text-2xl cursor-pointer'>
              <img className='h-[60px] bg-purp' src={Logo} alt='Logo' />
            </span>
          </Link>

          <span onClick={handleNav} className='cursor-pointer md:hidden block ml-auto'>
            {nav ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
          </span>
        </div>

        <ul className='hidden md:flex md:items-center md:justify-center w-full'>
          <li className='mx-4 my-6 md:my-0 text-xl duration-500 hover:text-gray-400'>
            <Link to='/adminleaderboard'>View Leadership board</Link>
          </li>
          <li className='mx-4 my-6 md:my-0 text-xl duration-500 hover:text-lightblue'>
            <Link to='/appointmentlist'>Appointment list</Link>
          </li>
          <li
            onClick={() => setShowPopup(true)}
            className='mx-4 my-6 md:my-0 text-xl cursor-pointer duration-500 hover:text-lightblue'
          >
            Add Admin
          </li>
          <li className='mx-4 my-6 md:my-0 md:text-xl duration-500 bg-transparent border border-white rounded-lg md:px-6 hover:text-gray-400'>
            <Button
              sx={{
                color: "#fff",
              }}
              onClick={() => {
                logOut();
              }}
            >
              Log Out
            </Button>
          </li>
        </ul>
      </nav>

      {/* Add Admin Popup */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-5 w-11/12 sm:w-1/2 lg:w-1/3 relative">
            <button
              onClick={() => setShowPopup(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              ✖
            </button>
            <h2 className="text-xl font-semibold text-gray-700 mb-4">Add Admin</h2>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-4">
                <input
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  placeholder="Enter admin email"
                  className={`p-2 border rounded-lg w-full ${
                    formik.errors.email && formik.touched.email ? 'border-red-500' : 'border-gray-300'
                  }`}
                />
                {formik.errors.email && formik.touched.email && (
                  <p className="text-red-500 text-sm mt-1">{formik.errors.email}</p>
                )}
              </div>
              <button
                type="submit"
                className="bg-purp text-white px-4 py-2 rounded  w-full"
              >
               {isLoading? "Loading": "Add" }
              </button>
            </form>
          </div>
        </div>
      )}
      <ToastContainer/>
    </div>
  );
}

export default Nav;
