


import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // For form validation
import Image1 from '../Assets/Frame 1618869553.png';
import Image2 from '../Assets/DGU.png';
import Image3 from '../Assets/love.png';
import Image4 from '../Assets/better.png';
import { toast,ToastContainer } from 'react-toastify';
import { Button } from '@mui/material';
import bookingClass from '../Auth/booking.class';
import { getAuthUser } from '../Auth/auth';

function BookSession() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true); 
  const [selectedCategory, setSelectedCategory] = useState(''); // State for selected category
  const [customCategory, setCustomCategory] = useState("");
  const isUserLoggedIn = getAuthUser() ? true : false;
  const images = [Image1, Image2, Image3, Image4];


  const [dropdownOpen, setDropdownOpen] = useState(false);

  // State for selected category
  


  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible(false); 

      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length); 
        setIsVisible(true); 
      }, 1000); 

    }, 5000); 

    return () => clearInterval(interval); 
  }, [images.length]);
  const user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
  const formik = useFormik({
    initialValues: {
      firstName: user?.first_name ? user?.first_name : '',
      lastName: user?.last_name ? user?.last_name : '',
      email: user?.email ? user?.email :'',
      category: '' // Initial value for category
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      category: Yup.string().required('Category is required') // Validation for category
    }),
    onSubmit: async(values) => {
      const { firstName, lastName, email, category } = values;
      try{
        if(!isUserLoggedIn){
          return  toast("Please sign up or login to book a session");
        }
        const res = await bookingClass.createClass({
          "category":values['category']
        })
        
        if(res?.data?.success){
          console.log("success")
          const calendlyUrl = `https://calendly.com/victorakan70?name=${encodeURIComponent(firstName + ' ' + lastName)}&email=${encodeURIComponent(email)}&category=${encodeURIComponent(category)}`;
          window.location.href = calendlyUrl
          // window.open(calendlyUrl, '_blank');
        }else{
          toast(res?.data?.message)
        }
        console.log("response from booking class",res)
      }catch(err){
        toast(err.message)
      }
    }
  });

  return (
    <div className='w-full bg-llblue mt-10 pb-10' id="session">
      <div className='max-w-[1240px] grid md:grid-cols-2 gap-5 mx-auto'>
        <div className="flex flex-col items-center bg-white rounded-md mt-10 font-poppins p-4">
          <h1 className="text-fblue text-center text-2xl md:text-4xl mt-3 mb-4">BOOK A SESSION WITH US</h1>
          <p className="text-center max-w-md mb-6">
            Have you ever struggled with emptiness? You are not alone in this world; we are here to help you restore your hope by booking a session and talking to our representative.
          </p>

          {/* Formik Form */}
          <form className="w-full max-w-lg space-y-4 mt-6" onSubmit={formik.handleSubmit}>
            <div className="flex flex-col md:flex-row gap-4">
              <div className="w-full md:w-1/2 flex flex-col">
                <label htmlFor="firstName" className="text-gray-700 mb-1">
                  First Name
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="p-2 border border-gray-300 rounded-lg"
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="text-red-500 text-sm">{formik.errors.firstName}</div>
                ) : null}
              </div>

              <div className="w-full md:w-1/2 flex flex-col">
                <label htmlFor="lastName" className="text-gray-700 mb-1">
                  Last Name
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="p-2 border border-gray-300 rounded-lg"
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="text-red-500 text-sm">{formik.errors.lastName}</div>
                ) : null}
              </div>
            </div>

            <div className="flex flex-col">
              <label htmlFor="email" className="text-gray-700 mb-1 mt-5">
                Email Address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="p-2 border border-gray-300 rounded-lg"
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500 text-sm">{formik.errors.email}</div>
              ) : null}
            </div>

            {/* Category Dropdown */}
            <div className="flex flex-col">
  <label htmlFor="category" className="text-gray-700 mb-1 mt-5">
    Select Category
  </label>

  <div className="relative">
    {/* Dropdown Trigger */}
    <button
      type="button"
      className="p-2 border border-gray-300 rounded-md bg-white shadow-sm w-full text-left"
      onClick={() => setDropdownOpen(!dropdownOpen)} // Toggle dropdown
    >
      {selectedCategory || "Select a Category"}
      <span className="float-right">▼</span>
    </button>

    {/* Dropdown Menu */}
    {dropdownOpen && (
      <ul className="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-md w-full">
        {[
          "Drugs",
          "Depression",
          "Anxiety",
          "Pornography",
          "Addiction",
          "Church hurt",
          "Others",
          "Type here",
        ].map((option, index) => (
          <li
            key={index}
            className={`p-3 hover:bg-gray-100 cursor-pointer text-center ${
              index < 7 ? "border-b border-gray-300" : ""
            }`}
            onClick={() => {
              setSelectedCategory(option);
              setDropdownOpen(false);

              // Update Formik's category field
              if (option === "Type here") {
                formik.setFieldValue("category", customCategory || ""); // Set customCategory if available
              } else {
                formik.setFieldValue("category", option);
              }
            }}
          >
            {option}
          </li>
        ))}
      </ul>
    )}

    {/* Input field for custom category */}
    {selectedCategory === "Type here" && (
      <input
        type="text"
        value={customCategory}
        onChange={(e) => {
          setCustomCategory(e.target.value);
          formik.setFieldValue("category", e.target.value); // Update Formik field
        }}
        placeholder="Type your category here"
        className="p-2 border border-gray-300 rounded-lg w-full mt-2"
      />
    )}
  </div>

  {formik.touched.category && formik.errors.category ? (
    <div className="text-red-500 text-sm">{formik.errors.category}</div>
  ) : null}
</div>


            <Button
  disabled={formik.isSubmitting}
  sx={{
    backgroundColor: 'primary.main', // 'bg-purp' replacement
    color: 'white',
    width: '250px',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    paddingY: 1, // for py-2 equivalent
    paddingX: 2, // for px-4 equivalent
    borderRadius: 2, // 'rounded-lg'
    mt: 3, // 'mt-6' equivalent
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: 'secondary.main', // 'hover:bg-blue-700' replacement
    },
  }}
  type="submit"
>
   {formik.isSubmitting ? "please wait..." : "Book Session"}
</Button>
          </form>
        </div>

        <div className="hidden lg:flex bg-lgreen p-4 md:pl-6 mx-auto mt-10 md:ml-auto lg:w-max">
          <img
            src={images[currentImageIndex]} 
            alt="Session"
            className={`bg-lpurp p-6 md:pl-8 w-[500px] transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`} 
          />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default BookSession;

