import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight, FaMedal, FaSearch, FaStar } from 'react-icons/fa';
import Navbar from './Navbar';
import AuthService from '../Auth/AuthService';
import { CircularProgress, Pagination, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

function LeaderBoard() {
    const [searchTerm, setSearchTerm] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [leaderBoard, setLeaderBoard] = useState([]);
    const [loading, setLoading] = useState(true);  // Loading state
    const [pag, setPag] = useState(null); // Pagination state
    const [startDate, setStartDate] = useState(null); // Start Date state
    const [endDate, setEndDate] = useState(null); // End Date state
    const itemsPerPage = 15;

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setCurrentPage(1); // Reset to page 1 on date change
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setCurrentPage(1); // Reset to page 1 on date change
    };

    const filteredData = leaderBoard.filter((user) =>
        user.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        const getLeaderBoard = async ({
            start_date:startDate,
            end_date:endDate,
            search: searchTerm,
            per_page: itemsPerPage,
           
        }) => {
            try {
                setLoading(true); // Set loading state to true while fetching data
                const res = await AuthService.leaderBoard({
                    page: currentPage,
                    search: searchTerm,
                    start_date: startDate ? startDate.toISOString() : null, // Add start date
                    end_date: endDate ? endDate.toISOString() : null, // Add end date
                });
                console.log("res?.data", res?.data);
                if (res?.data?.success) {
                    setPag({
                        page: res?.data?.page,
                        per_page: res?.data?.per_page,
                        total: res?.data?.total,
                        total_pages: res?.data?.total_pages
                    });
                    setLeaderBoard(res?.data?.data);
                }
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false); // Set loading to false after data is fetched
            }
        };
        getLeaderBoard();
    }, [currentPage, searchTerm, startDate, endDate]); // Add startDate and endDate as dependencies

    console.log("leaderBoard", leaderBoard);

    return (
        <div className='w-full font-poppins pb-5'>
            <div className='flex flex-col mx-auto'>
                <h1 className='text-purp font-semibold text-2xl md:text-3xl font-poppins text-center mt-5'>
                    BOH LEADERSHIP BOARD
                </h1>

                <div className="flex items-center mt-5 justify-center w-full">
                    <div className="relative w-full max-w-xs sm:max-w-md md:max-w-lg">
                        <input
                            type="text"
                            placeholder="Search users by name..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="p-2 bg-lblue border border-gray-300 rounded-full w-full pr-10 pl-4"
                        />
                        <FaSearch className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                    </div>
                </div>

                {/* Date filter section */}
                <div className="mt-5 flex justify-center gap-5">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={handleStartDateChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={handleEndDateChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </div>
            </div>

            <div className='overflow-x-auto mt-5 flex justify-center'>
                {loading ? ( // Display loading spinner when fetching data
                    <CircularProgress />
                ) : (
                    <table className='min-w-[300px] max-w-[800px] w-full bg-white rounded-lg'>
                        <thead>
                            <tr className='bg-purp text-white'>
                                <th className='py-2 px-4 border-b'>Rank</th>
                                <th className='py-2 px-4 border-b'>Name</th>
                                <th className='py-2 px-4 border-b'>Total Score</th>
                                <th className='py-2 px-4 border-b'>Number of Link Clicks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {leaderBoard.length > 0 ? (
                                leaderBoard.map((user, index) => (
                                    <tr key={user.id} className="hover:bg-gray-100">
                                        <td className='py-2 px-4 border-b text-center'>
                                            {index + (currentPage - 1) * itemsPerPage === 0 ? (
                                                <FaMedal className='text-yellow-500 text-xl inline' />
                                            ) : index + (currentPage - 1) * itemsPerPage === 1 ? (
                                                <FaMedal className='text-gray-400 text-xl inline' />
                                            ) : index + (currentPage - 1) * itemsPerPage === 2 ? (
                                                <FaMedal className='text-yellow-700 text-xl inline' />
                                            ) : (
                                                index + 1 + (currentPage - 1) * itemsPerPage
                                            )}
                                        </td>
                                        <td className='py-2 px-4 border-b text-center'>
                                            {user.first_name + " " + user?.last_name}
                                        </td>
                                        <td className='py-2 px-4 border-b text-center'>
                                            <FaStar className='text-yellow-500 inline mr-1' /> { (user?.sub_count*3) + user?.links_count || 0} points
                                        </td>
                                        <td className='py-2 px-4 border-b text-center'>
                                           {user?.sub_count + user?.links_count} total clicks
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className="text-center py-4">
                                        No users found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </div>

            <div className="flex justify-center mt-4 items-center space-x-4">
                {pag && <Pagination
                    count={pag?.total_pages}
                    page={pag?.page}
                    onChange={handlePageChange}
                    color="primary"
                />}
            </div>
        </div>
    );
}

export default LeaderBoard;
