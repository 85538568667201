import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../Components/Navbar';
import { FaSearch, FaYoutube } from 'react-icons/fa';
import VideoClass from '../Auth/video.class';
import Pagination from '@mui/material/Pagination';
import { CircularProgress } from '@mui/material';
import { toast,ToastContainer } from 'react-toastify';
import AuthService from '../Auth/AuthService';
import { truncateString } from '../Auth/auth';

function VideoPage() {
    const [searchTerm, setSearchTerm] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [allVideo, setAllVideo] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [authUser, setAuthUser] = useState(null)
    const dropdownRef = useRef(null);

    const topics = ['Depression', 'Lust', 'Anger', 'Finances', 'Discipline'];
    useEffect(()=>{
        const getAuthUser = async()=>{
            try{
                const res = await AuthService.authUser()
                console.log("res from aut user",res?.data);
                if(res?.data?.success){
                    console.log("res?.data?.data",res?.data?.data)
                    setAuthUser(res?.data?.data)
                }
            }catch(err){
                console.log(err);
            }
        }
        getAuthUser()
    },[])
    const handleSearchChange = (e) => {
        setSelectedTopic(null);
        setSearchTerm(e.target.value);
    };

    const handleTopicChange = (topic) => {
        setSearchTerm(null);
        setSelectedTopic(topic);
        setIsDropdownOpen(false);
    };

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const fetchVideos = async (page) => {
        setIsLoading(true);
        try {
            const res = await VideoClass.getAll({
                page,
                per_page: 10,
                search: searchTerm,
                category:selectedTopic
            });
            console.log("Fetched videos:", res?.data);
            setAllVideo(res?.data?.videos || []); // Ensure it's an empty array if no videos
            setTotalPages(res?.data?.total_pages || 0);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchVideos(currentPage);
    }, [currentPage, searchTerm, selectedTopic]);
    function copyToClipboard(ref) {
        const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || "https://boh-jcfa.onrender.com";
        const url = authUser?.id ? `${baseUrl}/video/${ref}/${authUser?.id}` :`${baseUrl}/video/${ref}` ; // URL to copy


        navigator.clipboard.writeText(url)
            .then(() => {
                toast.info("URL copied to clipboard!"); // Use toast instead of alert
            })
            .catch(err => {
                console.error("Failed to copy: ", err);
            });
    }
    return (
        <div className='w-full'>
            <Navbar />
            <h1 className='text-purp font-semibold text-2xl md:text-3xl font-poppins text-center mt-5'>
                BOH VIDEOS AND PODCASTS
            </h1>

            <div className="flex justify-center items-center mt-5">
                <div className="flex flex-col md:flex-row gap-5 items-center justify-center">
                    <div className="flex items-center relative">
                        <input
                            type="text"
                            placeholder="Search videos..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="p-2 bg-lblue border border-gray-300 rounded-full w-full md:w-80 pr-10"
                        />
                        <FaSearch className="absolute right-3 text-gray-500" />
                    </div>

                    <div className="relative w-full md:w-80" ref={dropdownRef}>
                        <div
                            onClick={toggleDropdown}
                            className="flex items-center p-2 bg-lblue border border-gray-300 rounded-md cursor-pointer"
                        >
                            <span className="flex-1">{selectedTopic || 'Filter by Category'}</span>
                        </div>
                        {isDropdownOpen && (
                            <div className="absolute top-full left-0 mt-2 bg-white border border-gray-300 rounded-md w-full z-20">
                                {topics.map((topic, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleTopicChange(topic)}
                                        className="p-2 hover:bg-gray-100 cursor-pointer"
                                    >
                                        {topic}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Loading Animation */}
            {isLoading ? (
                <div className="flex justify-center mt-10">
                    <CircularProgress />
                </div>
            ) : allVideo.length === 0 ? ( // Check if no videos are found
                <div className="flex justify-center mt-10 text-gray-500">
                    <p>No videos found. Please try a different search or topic.</p>
                </div>
            ) : (
                <div className="max-w-[1240px] mt-10 md:mt-20 grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 gap-5 p-4 mx-auto">
                    {allVideo.map((item, id) => (
                        <div key={id} className="font-poppins flex flex-col  border border-[#00000012] rounded-[12px] p-4">
                            <img src={item.image} alt={item.title} className="h-[300px] object-cover w-full mx-auto rounded-[12px]" />
                            <div onClick={()=>{
                            copyToClipboard(item?.ref)
                        }} class="flex justify-end relative -top-[50px] p-1.5">
                            <img src='/share.svg' />
                            </div>
                            <h1 className="mt-3 mb-3 text-center font-semibold">{item.title}</h1>
                            <p className="text-center mx-auto max-w-sm p-2">{truncateString(item.summary) }</p>
                            <a href={`${item?.link}`} target="_blank" rel="noopener noreferrer">
                                <button className="mx-auto flex mt-3 h-[50px] items-center bg-[#B20606] rounded-[12px] text-white font-semibold py-2 min-w-full max-w-md justify-center text-sm md:py-2 md:px-4 hover:bg-red-700 transition duration-300">
                                    <FaYoutube className="mr-2" size={15} />
                                    Watch on YouTube
                                </button>
                            </a>
                        </div>
                    ))}
                </div>
            )}

            {/* MUI Pagination Component */}
            <div className="flex justify-center mt-5">
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    variant="outlined"
                    shape="rounded"
                />
            </div>
            <ToastContainer hideProgressBar={true}/>
        </div>
    );
}

export default VideoPage;
