import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "./Components/Home";
import LeaderBoard from "./Components/LeaderBoard";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import AdminHome from "./Admin/AdminHome";
import AdminLeaderBoard from "./Admin/AdminLeaderBoard";
import UserLeaderBoard from "./Admin/UserLeaderBoard";
import SessionPage from "./Pages/SessionPage";
import ScrollToTop from "./Components/ScrollToTop";
import UploadVideo from "./Admin/UploadVideo";
import VideoPage from "./Pages/VideoPage";
import UserHome from "./Components/UserHome";
import UploadVidLink from "./Admin/UploadVidLink";
import 'react-toastify/dist/ReactToastify.css';
import Appointment from "./Admin/Appointment";

// Component to handle protected routes
const ProtectedRoute = ({ children, allowedRoles }) => {
  const user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));

  // Check if the user is logged in
  if (!user) {
    return <Navigate to="/" />;
  }
console.log("allowedRoles && !allowedRoles.includes(user.userType)",allowedRoles && !allowedRoles.includes(user.userType),!allowedRoles.includes(user.userType),allowedRoles)
  // Check if the user has an allowed role
  if (allowedRoles && !allowedRoles.includes(user.userType)) {
    return <Navigate to="/" />;
  }

  return children;
};

function App() {
  
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            {/* Public routes */}
            <Route path="/" index element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/sessionpage" element={
              <SessionPage />
              } /> 

            {/* Protected Admin routes */}
            <Route 
              path="/admin" 
              element={
                <ProtectedRoute allowedRoles={['Admin']}>
                  <AdminHome />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/adminleaderboard" 
              element={
                <ProtectedRoute allowedRoles={['Admin']}>
                  <AdminLeaderBoard />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/appointmentlist" 
              element={
                <ProtectedRoute allowedRoles={['Admin']}>
                  <Appointment />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/uploadvideo" 
              element={
                <ProtectedRoute allowedRoles={['Admin']}>
                  <UploadVideo />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/uploadvidlink" 
              element={
                <ProtectedRoute allowedRoles={['Admin']}>
                  <UploadVidLink />
                </ProtectedRoute>
              } 
            />

            {/* Protected User routes */}
            <Route 
              path="/leaderboard" 
              element={
                <ProtectedRoute allowedRoles={['Admin', 'User']}>
                  <LeaderBoard />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/userpage" 
              element={
                <ProtectedRoute allowedRoles={['Admin','User']}>
                  <UserHome />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/userleaderboard" 
              element={
                <ProtectedRoute allowedRoles={['Admin','User']}>
                  <UserLeaderBoard />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/videopage" 
              element={
                <ProtectedRoute allowedRoles={['Admin','User']}>
                  <VideoPage />
                </ProtectedRoute>
              } 
            />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
