import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Nav from './Nav';
import { FaArrowLeft, FaUpload } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import VideoClass from '../Auth/video.class';

function UploadStatusModal({ isOpen, status, onClose }) {
  useEffect(() => {
    if (status === 'success' || status === 'error') {
      const timer = setTimeout(onClose, 3000);
      return () => clearTimeout(timer);
    }
  }, [status, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        {status === 'loading' && (
          <div>
            <p className="text-lg mb-2">Uploading video...</p>
            <div className="loader"></div>
          </div>
        )}
        {status === 'success' && (
          <div>
            <p className="text-lg text-green-600">Video uploaded successfully!</p>
            <button onClick={onClose} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-lg">
              Close
            </button>
          </div>
        )}
        {status === 'error' && (
          <div>
            <p className="text-lg text-red-600">Upload failed. Please try again.</p>
            <button onClick={onClose} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-lg">
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

function UploadVidLink() {
  const [videoUrl, setVideoUrl] = useState('');
  const [videoDetails, setVideoDetails] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('/api/categories'); // Replace with your actual API endpoint
        setCategories(response.data || []);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setCategories(['Depression', 'Lust', 'Anger', 'Finances', 'Discipline']);
      }
    };

    fetchCategories();
  }, []);

  const handleFetchVideoDetails = async () => {
    try {
      setFetching(true);
      const res = await VideoClass.getVideoByUrl({ url: videoUrl });
      if (res?.data?.success) {
        setVideoDetails(res.data.data);
      } else {
        toast.error('Failed to fetch video details.');
      }
    } catch (err) {
      console.error('Error fetching video details:', err);
      toast.error('Error fetching video details. Please try again.');
    } finally {
      setFetching(false);
    }
  };

  const handleSubmit = async () => {
    if (!videoDetails) {
      toast.error('No video details available for upload.');
      return;
    }
    if (!category) {
      toast.error('Please select a category.');
      return;
    }

    setIsModalOpen(true);
    setUploadStatus('loading');
    setIsLoading(true);

    const data = {
      title: videoDetails.title,
      link: videoUrl,
      summary: videoDetails.description,
      image: videoDetails.thumbnails?.maxres?.url || '',
      category,
    };

    try {
      const response = await VideoClass.upload(data);
      toast(response?.data?.message || 'Upload successful');
      if (response?.data?.success) {
        window.location.href = '/';
      }
      setUploadStatus('success');
    } catch (error) {
      console.error('Upload failed:', error);
      setUploadStatus('error');
      toast.error('Error uploading video. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full font-poppins pb-5">
      <Nav />
      <div className="flex items-center p-8 gap-3">
        <Link to="/admin"><FaArrowLeft size={20} /></Link>
        <h1 className="text-blue-500 text-xl md:text-2xl">Upload New Video</h1>
      </div>
      <hr className="border-t border-gray-300 w-full mt-2" />

      <div className="flex flex-col p-4 items-center mt-8">
        {/* Video URL Input */}
        <div className="w-full max-w-md lg:max-w-2xl mb-6">
          <label className="block text-gray-700 text-lg mb-2 tracking-wider">Embedded Video Link</label>
          <input
            type="text"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
            placeholder="Paste link here"
            className="w-full p-3 border border-blue-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={handleFetchVideoDetails}
            disabled={fetching}
            className={`mt-2 ${fetching ? 'bg-gray-500' : 'bg-blue-500'} text-white py-2 px-4 rounded-lg hover:bg-blue-700`}
          >
            {fetching ? 'Loading...' : 'Fetch Video Details'}
          </button>
        </div>

        {/* Category Selection */}
        <div className="w-full max-w-md lg:max-w-2xl mb-6">
          <label className="block text-gray-700 text-lg mb-2 tracking-wider">Category</label>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="w-full p-3 border border-blue-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Select a category</option>
            {Array.isArray(categories) && categories.map((cat, index) => (
              <option key={index} value={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>

        {/* Video Details Display */}
        {videoDetails?.thumbnails?.maxres?.url && (
          <div className="w-[200px] h-[200px] object-cover">
            <img src={videoDetails.thumbnails.maxres.url} alt="Video Thumbnail" />
          </div>
        )}

        {videoDetails?.title && (
          <div className="w-full max-w-md lg:max-w-2xl mb-6">
            <label className="block text-gray-700 text-lg mb-2 tracking-wider">Video Title</label>
            <input
              type="text"
              value={videoDetails.title}
              readOnly
              className="w-full p-3 border border-blue-500 rounded-lg bg-gray-100"
            />
          </div>
        )}

        {videoDetails?.description && (
          <div className="w-full max-w-md lg:max-w-2xl mb-6">
            <label className="block text-gray-700 text-lg mb-2 tracking-wider">Video Summary</label>
            <textarea
              value={videoDetails.description}
              readOnly
              className="w-full h-28 p-3 border border-blue-500 rounded-lg bg-gray-100"
            />
          </div>
        )}

        {/* Submit Button */}
        {videoDetails && (
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className={`flex items-center ${isLoading ? 'bg-gray-500' : 'bg-blue-500'} text-white py-2 px-4 rounded-lg hover:bg-blue-700`}
          >
            <FaUpload className="mr-2" />
            {isLoading ? 'Uploading...' : 'Upload Video'}
          </button>
        )}
      </div>

      <UploadStatusModal
        isOpen={isModalOpen}
        status={uploadStatus}
        onClose={() => setIsModalOpen(false)}
      />
      <ToastContainer />
    </div>
  );
}

export default UploadVidLink;
